import { render } from "react-dom";
import React from "react";
import Basket from "./basket/components/basket";

var basket = document.querySelector(".header-items__basket");
var basket_path = basket.getAttribute("data-basket-url");

render(
  React.createElement(Basket, { basket_path }),
  document.querySelector(".header-items__basket")
);
