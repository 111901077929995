import React from "react";
import axios from "axios";

class BasketPreview extends React.Component {
  constructor(props) {
    super(props);
    this.removeLine = this.removeLine.bind(this);
    this.state = {
      display: false
    };

    PubSub.subscribe("BASKET_UPDATE", (msg, data) => {
      this.setState({ display: true });
      setTimeout(() => {
        this.setState({ display: false });
      }, 3000);
    });
  }
  removeLine(line) {
    axios
      .delete("/basket/remove_line", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: {
          basket_line: line
        }
      })
      .then(() => {
        PubSub.publish("BASKET_UPDATE");
      });
  }
  render() {
    let empty_message;
    if (this.props.contents.length === 0) {
      empty_message = <div className="basket-preview__empty-basket">Your basket is empty</div>;
    }
    return (
      <div className={`basket-preview ${this.state.display}`}>
        <div className="basket-preview__triangle" />
        <div className="basket-preview__summary">
          <span>{this.props.contents.length} item(s)</span> in Basket
        </div>
        <ul className="basket-preview__items">
          {this.props.contents.map((line, index) => {
            return (
              <li className="basket-preview__item" key={index}>
                <img
                  className="basket-preview__item__image"
                  src={line.image.replace("medium", "thumbnail")}
                />
                <div className="basket-preview__item__info">
                  <div className="basket-preview__item__description">
                    {line.product}
                  </div>
                  <div className="basket-preview__item__qty">
                    x{line.quantity}
                  </div>
                  <div
                    className="basket-preview__item__remove"
                    onClick={() => {
                      this.removeLine(line.id);
                    }}
                  >
                    <i className="material-icons">&#xE5C9;</i>
                  </div>
                </div>
              </li>
            );
          })}
          {empty_message}
        </ul>
        <div className="basket-preview__request">
          <a className="basket-preview__request-btn" href={this.props.basket_path}>
            Request a Quote
          </a>
        </div>
      </div>
    );
  }
}

BasketPreview.propTypes = {
  contents: React.PropTypes.array
};

export { BasketPreview as default };
