import React from "react";
import axios from "axios";
import BasketPreview from "./basket_preview";

class Basket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contents: []
    };

    this.getBasket();
    this.navigateToBasket = this.navigateToBasket.bind(this);
  }

  componentWillMount() {
    PubSub.subscribe("BASKET_UPDATE", (msg, data) => {
      this.getBasket();
    });
  }

  navigateToBasket() {
    window.location = this.props.basket_path;
  }

  getBasket() {
    axios
      .get("/basket", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        this.setState({
          loading: false,
          num_lines: response.data.basket.num_lines,
          contents: response.data.basket.contents
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          num_lines: 0,
          contents: []
        });
      });
  }
  render() {
    return (
      <div className="basket--container">
        <i className="material-icons" onClick={this.navigateToBasket}>
          &#xE54E;
        </i>
        {this.state.num_lines > 0 ? (
          <span className="count" onClick={this.navigateToBasket}>
            {this.state.num_lines}
          </span>
        ) : ""}
        <BasketPreview
          contents={this.state.contents}
          basket_path={this.props.basket_path}
        />
      </div>
    );
  }
}

export { Basket as default };
